/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    h3: "h3",
    br: "br",
    ol: "ol",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-1-trebuchet"
  }, "Day 1: Trebuchet?!"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2023/day/1"
  }, "https://adventofcode.com/2023/day/1")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2023/solutions/src/day_01.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "Something is wrong with the global snow production and you are the lucky fixer! (yay?)"), "\n", React.createElement(_components.p, null, "Snow comes from the sky, and the sky is where you are going.\nThe vehicle of choice to get to the destination is a trebuchet."), "\n", React.createElement(_components.p, null, "Today’s input is a list of calibration instructions for that trebuchet."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "1abc2\npqr3stu8vwx\na1b2c3d4e5f\ntreb7uchet\n")), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "Each line of input holds a calibration value.\nA calibration value is a two-digit number."), "\n", React.createElement(_components.p, null, "It can be constructed by concatenating the first digit in a line with the last digit in that line.\nThe question asks for sum of all calibration values in the input."), "\n", React.createElement(_components.h3, {
    id: "option-1-a-for-loop"
  }, "Option 1: A for loop"), "\n", React.createElement(_components.p, null, "Some skeleton/pseudo-code to start with:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let mut sum = 0;\nfor line in input.lines() {\n    let first = // first digit in line\n    let last = // last digit in line;\n    let num = first * 10 + last; // smoosh the two digits together really close\n    sum += num; // add the combined two-digit number to the sum\n}\nsum\n")), "\n", React.createElement(_components.p, null, "I chose to not overthink this one and to be very verbose on purpose."), "\n", React.createElement(_components.p, null, "Starting to search from the front of the line, I look for the first character that is a digit.\nThen I turn that into an integer so I can do math on it."), "\n", React.createElement(_components.p, null, "To find the last digit, I do the same thing starting from the back of the line."), "\n", React.createElement(_components.p, null, "When I found both digits, I merge them into a two-digit number and add that number to the final sum."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let mut sum = 0;\nfor line in input.lines() {\n    let first = line.chars().find(|c| c.is_ascii_digit()).unwrap();\n    let first: u32 = first.to_digit(10).unwrap();\n    let last = line.chars().rfind(|c| c.is_ascii_digit()).unwrap();\n    let last: u32 = last.to_digit(10).unwrap();\n    let num = first * 10 + last;\n    sum += num;\n}\nsum\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-an-iterator-chain"
  }, "Option 2: An iterator chain"), "\n", React.createElement(_components.p, null, "The exact same idea, but implemented a bit differently."), "\n", React.createElement(_components.p, null, "Split the input into lines.\nTurn each line into a number.\nSum those numbers."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "input\n    .lines()\n    .map(|line| {\n        let first = line.chars().find_map(|c| c.to_digit(10)).unwrap();\n        let last = line.chars().rev().find_map(|c| c.to_digit(10)).unwrap();\n        10 * first + last\n    })\n    .sum()\n")), "\n", React.createElement(_components.h3, {
    id: "code"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs"
  }, "fn part_1(input: &str) -> u32 {\n    input\n        .lines()\n        .map(|line| {\n            let first = line.chars().find_map(|c| c.to_digit(10)).unwrap();\n            let last = line.chars().rev().find_map(|c| c.to_digit(10)).unwrap();\n            10 * first + last\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "It turns out spelled out digits are also valid.", React.createElement(_components.br), "\n", "So “one”, “two”, “three”, “four”, “five”, “six”, “seven”, “eight”, and “nine” also count as valid “digits”,\nwith values from 1 to 9 respectively."), "\n", React.createElement(_components.p, null, "Input lines can look like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "two1nine\neightwothree\nabcone2threexyz\nxtwone3four\n4nineeightseven2\nzoneight234\n7pqrstsixteen\n")), "\n", React.createElement(_components.p, null, "For example, the first digit on the first line would be ", React.createElement(_components.code, null, "\"two\""), ", with a numeric value of ", React.createElement(_components.code, null, "2"), ".", React.createElement(_components.br), "\n", "The last digit on that line is ", React.createElement(_components.code, null, "\"nine\""), ", with a value of ", React.createElement(_components.code, null, "9"), ".", React.createElement(_components.br), "\n", "That means the calibration value for that line is ", React.createElement(_components.code, null, "29"), "."), "\n", React.createElement(_components.p, null, "This complicates things, because you can’t look at a line 1 character at a time like in part 1."), "\n", React.createElement(_components.p, null, "The skeleton code remains unchanged from part 1."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let mut sum = 0;\nfor line in input.lines() {\n    let first = // first digit in line\n    let last = // last digit in line;\n    let num = first * 10 + last; // smoosh the two digits together really close\n    sum += num; // add the combined two-digit number to the sum\n}\nsum\n")), "\n", React.createElement(_components.p, null, "To find the first digit, I create a temporary line that starts off as the entire line.\nIf it starts with a valid prefix, the digit was found.\nIf it does not, I shorten that temporary variable so it starts one character later and do the check again."), "\n", React.createElement(_components.p, null, "To find the last digit, I apply the same logic with 2 changes:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "I check if the temporary line ", React.createElement(_components.strong, null, "ends"), " with a valid suffix."), "\n", React.createElement(_components.li, null, "I shorten that temporary line by removing one character from the ", React.createElement(_components.strong, null, "end"), "."), "\n"), "\n", React.createElement(_components.p, null, "In pseudocode that looks like:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let nums = // a map with all possible valid strings as key, and their number as value. (ie. key: \"nine\", value: 9)\nfor line in lines {\n    let mut first = 0;\n    'first: loop {\n        let mut temporary = line;\n        for (prefix, digit) in nums {\n            if line.starts_with(prefix) {\n                first = digit;\n                // break out of the outer loop marked as 'first\n                break 'first;\n            }\n        }\n        // all valid digits were checked an none matched, shorten the temporary line by removing one character from the front\n        temporary = &temporary[1..];\n    }\n    // the same logic for the last digit\n    // the summing logic\n}\n")), "\n", React.createElement(_components.h3, {
    id: "code-1"
  }, "Code"), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Thanks to ", React.createElement(_components.a, {
    href: "https://www.christopherbiscardi.com/"
  }, "Chris Biscardi"), " for showing me a cleaner way to build the ", React.createElement(_components.code, null, "nums"), " map")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs"
  }, "use std::collections::HashMap;\n\nfn part_2(input: &str) -> u32 {\n    let nums = HashMap::from([\n        (\"1\", 1),\n        (\"2\", 2),\n        (\"3\", 3),\n        (\"4\", 4),\n        (\"5\", 5),\n        (\"6\", 6),\n        (\"7\", 7),\n        (\"8\", 8),\n        (\"9\", 9),\n        (\"one\", 1),\n        (\"two\", 2),\n        (\"three\", 3),\n        (\"four\", 4),\n        (\"five\", 5),\n        (\"six\", 6),\n        (\"seven\", 7),\n        (\"eight\", 8),\n        (\"nine\", 9),\n    ]);\n\n    let mut sum = 0;\n    for line in input.lines() {\n        let mut forwards = line;\n        let mut backwards = line;\n\n        let first = 'outer: loop {\n            for (prefix, num) in nums.iter() {\n                if forwards.starts_with(prefix) {\n                    break 'outer num;\n                }\n            }\n            forwards = &forwards[1..];\n        };\n\n        let last = 'outer: loop {\n            for (suffix, num) in nums.iter() {\n                if backwards.ends_with(suffix) {\n                    break 'outer num;\n                }\n            }\n            backwards = &backwards[..backwards.len() - 1];\n        };\n\n        let num = first * 10 + last;\n        sum += num;\n    }\n\n    sum\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_01.rs",
    numberLines: true
  }, "use std::collections::HashMap;\n\nfn main() {\n    let input = include_str!(\"day_01.txt\");\n    println!(\"{}\", part_1(input));\n    println!(\"{}\", part_2(input));\n}\n\n\nfn part_1(input: &str) -> u32 {\n    input\n        .lines()\n        .map(|line| {\n            let first = line.chars().find_map(|c| c.to_digit(10)).unwrap();\n            let last = line.chars().rev().find_map(|c| c.to_digit(10)).unwrap();\n            10 * first + last\n        })\n        .sum()\n}\n\nfn part_2(input: &str) -> u32 {\n    let nums = HashMap::from([\n        (\"1\", 1),\n        (\"2\", 2),\n        (\"3\", 3),\n        (\"4\", 4),\n        (\"5\", 5),\n        (\"6\", 6),\n        (\"7\", 7),\n        (\"8\", 8),\n        (\"9\", 9),\n        (\"one\", 1),\n        (\"two\", 2),\n        (\"three\", 3),\n        (\"four\", 4),\n        (\"five\", 5),\n        (\"six\", 6),\n        (\"seven\", 7),\n        (\"eight\", 8),\n        (\"nine\", 9),\n    ]);\n\n    let mut sum = 0;\n    for line in input.lines() {\n        let mut forwards = line;\n        let mut backwards = line;\n\n        let first = 'outer: loop {\n            for (prefix, num) in nums.iter() {\n                if forwards.starts_with(prefix) {\n                    break 'outer num;\n                }\n            }\n            forwards = &forwards[1..];\n        };\n\n        let last = 'outer: loop {\n            for (suffix, num) in nums.iter() {\n                if backwards.ends_with(suffix) {\n                    break 'outer num;\n                }\n            }\n            backwards = &backwards[..backwards.len() - 1];\n        };\n\n        let num = first * 10 + last;\n        sum += num;\n    }\n\n    sum\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
